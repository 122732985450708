import React, { useEffect, useState } from 'react'
import { api_url, titleDescription } from '../../config/Config';
import { Table } from 'react-bootstrap';
import _fetch from '../../config/api';
import dateformat from "dateformat";
import Modal from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import NoResult from './NoResult';
import SupportTab from '../../Private/SupportTab';
import ReactPaginate from 'react-paginate';

const FundHistory = (props: any) => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = `${props.pageTitle} | ${titleDescription}`;
    });
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState('');


    const onOpenModal = (image: any) => {
        setImage(image);
        setOpen(true);
    }
    const onCloseModal = () => setOpen(false);

    const [ticketHistory, setTicketHistory] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [page, setpage] = useState(1);

    useEffect(() => {
        getHistory();
        getfunddetails()
    }, [page]);

    const handlePageClick = async (event: any) => {
        const newOffset = (event.selected + 1);
        setpage(newOffset);
    };

    const getHistory = async () => {
        setLoading(true);
        let res = await _fetch(`${api_url}fund/getfund?limit=10&page=${page}`, "GET", {})
        if (res?.status === "success") {
            setLoading(false);
            setTicketHistory(res?.data);
            setTotalRecords(res?.total);
        }
    }
    const getfunddetails = async () => {
        setLoading(true);
        let res = await _fetch(`${api_url}fund/fund-details`, "GET", {})
        if (res?.status === "success") {
            setLoading(false);
            
        }
    }


    const TicketValues = [
        { id: 1, name: "Ava.... Fund" , price: '200' },
        { id: 2, name: "Pending fund" , price: '200' },
        { id: 3, name: "Used fund" , price: '200' },
    ];

    return (
        <main>


            <div className="ticket_view">

                {/* <div className='__ticket-card'>
                    <div className='row'>
                        {TicketValues.map((val, index) => (
                            <div className='col-md-4' key={index}>
                                <div className='__ticket'>
                                    <h4 className='__heading'> {val.name}</h4>
                                    <h5> {val.price} USDT</h5>
                                </div>
                            </div>
                        ))}

                    </div>
                </div> */}


                <div className="ticket_inner">
                    <div className="table_section ">

                        <div className="scrolling_table">
                            <div className="scroll_under">
                                <div className="table_responsive">
                                    <Table className="support_table all_tables">
                                        <thead className='thead_dashboard'>
                                            <tr className="table_heading_row">
                                                <th>{t("Amount")}</th>
                                                <th>{t("Type")}</th>

                                                <th>{t("Description")}</th>
                                                <th>{t("Date")}</th>
                                            </tr>
                                        </thead>
                                        <tbody className='tbody_dashboard'>
                                            {ticketHistory.length == 0 ? <tr className='no_data_tr'>
                                                <td colSpan={4}>
                                                    <NoResult />
                                                </td>
                                            </tr> :
                                                <>
                                                    {
                                                        ticketHistory?.map((item: any, index: any) => {
                                                            return (
                                                                <>
                                                                    <tr className="table_data_row">
                                                                        <td className="table_data">
                                                                            <div className="pic_text">${item?.amount?.toFixed(3)}</div>
                                                                        </td>
                                                                        <td className="table_data">{(item?.type?.toUpperCase())?.replace(/_/g, " ")}</td>
                                                                        <td className="table_data">{item?.remark}</td>
                                                                        <td className="table_data"> {dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")} </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </>
                                            }
                                        </tbody>
                                    </Table>
                                    {totalRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                        breakLabel="..."
                                        nextLabel=">"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={1}
                                        pageCount={totalRecords / 10}
                                        previousLabel="<"
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* image modal */}
            <Modal open={open} onClose={onCloseModal} center
                classNames={{
                    modal: "image_modal",
                }}>
                <img src={image} alt="ticket__img" />

            </Modal>
        </main>
    )
}

export default FundHistory