import React, { useRef, useEffect, useState } from 'react';

const Demo: React.FC = () => {

     const canvasRef = useRef<HTMLCanvasElement>(null);
     const [isCompleted, setIsCompleted] = useState(false);

     useEffect(() => {
          const canvas = canvasRef.current;
          if (!canvas) return;

          const ctx = canvas.getContext('2d');
          if (!ctx) return;

          // Set canvas dimensions
          canvas.width = canvas.offsetWidth;
          canvas.height = canvas.offsetHeight;

          // Fill canvas with a scratchable overlay
          ctx.fillStyle = '#cfced6';
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          ctx.globalCompositeOperation = 'destination-out';

          // Scratch logic
          const handleScratch = (event: MouseEvent | TouchEvent) => {
               const rect = canvas.getBoundingClientRect();
               const clientX = (event as MouseEvent).clientX || (event as TouchEvent).touches[0].clientX;
               const clientY = (event as MouseEvent).clientY || (event as TouchEvent).touches[0].clientY;

               const x = clientX - rect.left;
               const y = clientY - rect.top;

               ctx.beginPath();
               ctx.arc(x, y, 20, 0, Math.PI * 2);
               ctx.fill();

               checkScratchComplete();
          };

          // Check if 70% or more of the canvas has been scratched
          const checkScratchComplete = () => {
               const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
               const pixels = imageData.data;
               const totalPixels = pixels.length / 4;
               let transparentPixels = 0;

               for (let i = 3; i < pixels.length; i += 4) {
                    if (pixels[i] === 0) transparentPixels++;
               }

               const scratchedPercent = (transparentPixels / totalPixels) * 100;
               if (scratchedPercent > 85) {
                    setIsCompleted(true);
               }
          };

          const handleMouseDown = (event: MouseEvent | TouchEvent) => {
               handleScratch(event);
               canvas.addEventListener('mousemove', handleScratch);
               canvas.addEventListener('touchmove', handleScratch);
          };

          const handleMouseUp = () => {
               canvas.removeEventListener('mousemove', handleScratch);
               canvas.removeEventListener('touchmove', handleScratch);
          };

          canvas.addEventListener('mousedown', handleMouseDown);
          canvas.addEventListener('touchstart', handleMouseDown);
          canvas.addEventListener('mouseup', handleMouseUp);
          canvas.addEventListener('touchend', handleMouseUp);

          return () => {
               canvas.removeEventListener('mousedown', handleMouseDown);
               canvas.removeEventListener('touchstart', handleMouseDown);
               canvas.removeEventListener('mouseup', handleMouseUp);
               canvas.removeEventListener('touchend', handleMouseUp);
          };
     }, []);


     return (
          <div className="scratch-card-container">
               {!isCompleted ? (
                    <div className="scratch-card">
                         <canvas ref={canvasRef}></canvas>
                    </div>
               ) : (
                    <div className="reward">
                         🎉 Congratulations! You've revealed the card! 🎉
                    </div>
               )}
          </div>
     );
};

export default Demo;
